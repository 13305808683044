@font-face {
    font-family: 'Spoqa Han Sans Neo Light';
    font-style: normal;
    font-weight: 100;
    src: url("static/fonts/SpoqaHanSansNeo-Light.woff2") format('woff2'),
    url("static/fonts/SpoqaHanSansNeo-Light.woff") format('woff')
}

@font-face {
    font-family: 'Spoqa Han Sans Neo Regular';
    font-style: normal;
    font-weight: 400;
    src: url("static/fonts/SpoqaHanSansNeo-Regular.woff2") format('woff2'),
    url("static/fonts/SpoqaHanSansNeo-Regular.woff") format('woff')
}

@font-face {
    font-family: 'Spoqa Han Sans Neo Medium';
    font-style: normal;
    font-weight: 500;
    src: url("static/fonts/SpoqaHanSansNeo-Medium.woff2") format('woff2'),
    url("static/fonts/SpoqaHanSansNeo-Medium.woff") format('woff')
}

@font-face {
    font-family: 'Spoqa Han Sans Neo Bold';
    font-style: normal;
    font-weight: 700;
    src: url("static/fonts/SpoqaHanSansNeo-Bold.woff2") format('woff2'),
    url("static/fonts/SpoqaHanSansNeo-Bold.woff") format('woff')
}